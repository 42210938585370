import { useState, useEffect, Dispatch, SetStateAction } from "react";
import dark from '../styles/themes/dark';

type Response<T> = [
    T,
    Dispatch<SetStateAction<T>>,
];

export default function usePersistedState<T>(key: string, initialState: T): Response<T> {
    const [state, setState] = useState(() => {
        const storageValue = localStorage.getItem(key);

        if(storageValue){
            // Se o valor existe, use-o
            return JSON.parse(storageValue);
        } else {
            // Se não existe, use o tema escuro independente do initialState
            if (key === 'theme') {
                return dark;
            }
            return initialState;
        }
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    return [state, setState];
}