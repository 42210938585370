import Capitura from './assets/mock/Capitura.png';
import Container from './assets/mock/Container.png';
import Docker from './assets/mock/Docker.png';
import Exila from './assets/mock/Exila.png';
import Modo from './assets/mock/Modo.png';
import Exilio from './assets/mock/Exilio.png';
import RefactoolRealOutput from './assets/mock/refactool-real-output.svg';

const ProjectsData = [
    {
        "id": 1,
        "img": RefactoolRealOutput,
        "title": "Refactool - Analisador de Código",
        "description": "Ferramenta de análise de código que utiliza múltiplos provedores de IA para fornecer sugestões de melhoria em projetos.",
        "tool": "Python · AI · Git",
        "github": "https://github.com/gabrielsalvesdev/refactool"
    },
    {
        "id": 2,
        "img": Container,
        "title": "Container Watch Ansible Grafana",
        "description": "Ansible Containerize: Simplificando o monitoramento com Grafana, Ansible e cAdvisor, automatizando a configuração de servidores para o rápido provisionamento de um ambiente de monitoramento completo",
        "tool": "Ansible . Docker",
        "github": "https://github.com/23Ant/ContainerWatch-Ansible-Grafana",
    },
    {
        "id": 3,
        "img": Docker,
        "title": "Container Watch Dockercomposer",
        "description": "Docker composer para subir o grafana, prometheus e cadVisior, e com o script GO gerar retornos de acessos para serem monitorados.",
        "tool": "Ansible . Docker . Go",
        "github": "https://github.com/23Ant/ContainerWatch-Dockercomposer-scriptGO",
    },
    {
        "id": 4,
        "img": Exila,
        "title": "Exila",
        "description": "Exila é uma ferramenta de linha de comando desenvolvida para permitir salvar o conteúdo de pastas que contem arquivos legíveis em um só arquivo txt para apendizado de maquina",
        "tool": "Python . Shell Script",
        "github": "https://github.com/gabrielsalvesdev/exila",
    },
    {
        "id": 5,
        "img": Modo,
        "title": "Modo grafico XFCE",
        "description": "Se você precisa de gui (interface grafica) no seu servidor em nuvem, essa configuração vai te ajudar",
        "tool": "Shell Script",
        "github": "https://github.com/gabrielsalvesdev/modo_grafico_xfce",
    },
    {
        "id": 6,
        "img": Exilio,
        "title": "Exilio",
        "description": "Se você sofre em instalar suas ferramentas num novo servidor esse script vai poder ser util. (otimizado para raspberry pi)",
        "tool": "Shell Script",
        "github": "https://github.com/gabrielsalvesdev/exilio",
    },
    {
        "id": 7,
        "img": Capitura,
        "title": "Capitura de Wifi para Digispark",
        "description": "Projeto desenvolvido por mim faz a extração de informações de senha Wi-Fi de um computador, enviando as informações extraídas para um endereço de e-mail especificado",
        "tool": "C++ · PowerShell",
        "github": "https://github.com/gabrielsalvesdev/Cap_Wifi_Digispark",
    },
]

export default ProjectsData;