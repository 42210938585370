import React, { useContext, useState, useEffect } from 'react';
import Switch from 'react-switch';
import { ThemeContext } from 'styled-components';
import { FaMoon, FaSun } from "react-icons/fa";
import { IoMdInformationCircleOutline } from "react-icons/io";

import Typewriter from 'typewriter-effect';

import { ContainerPresentation, ContainerMe, TextName, SubText, Navigations, TextNavs, Imagem, ThemeTooltip, ThemeToggleWrapper } from './styles';

import hiImage from '../../assets/mock/hi.png';

interface Props {
    toggleTheme(): void;
}

const Presentation: React.FC<Props> = ({ toggleTheme }) => {
    const { title, colors } = useContext(ThemeContext);
    const [showTooltip, setShowTooltip] = useState(true);

    // Ocultar o tooltip após 5 segundos
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTooltip(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <ContainerPresentation>
            <ContainerMe>
                <TextName>Gabriel Sousa</TextName>
                <SubText>
                    <Typewriter
                        options={{
                            autoStart: true,
                            loop: true,
                            delay: 30,
                            strings: ["< DevOps />"]
                        }}
                    />
                </SubText>
            </ContainerMe>

            <Navigations>
                <TextNavs href="#sobre">Sobre</TextNavs>
                <TextNavs href="#projetos">Projetos</TextNavs>
                <TextNavs href="#contato">Contato</TextNavs>
                <ThemeToggleWrapper>
                    <li className='switchCenter'>
                        <Switch
                            onChange={toggleTheme}
                            checked={title === 'light'}
                            checkedIcon={<FaSun color="#fff" size={18} style={{ marginLeft: '2px', marginTop: '1px' }} />}
                            uncheckedIcon={<FaMoon color="#2E2F31" size={18} style={{ marginLeft: '2px', marginTop: '1px' }} />}
                            handleDiameter={20}
                            offColor="#DCE0E2"
                            onColor="#f9d71c"
                            activeBoxShadow="0 0 2px 3px #33bbff"
                        />
                    </li>
                    {showTooltip && (
                        <ThemeTooltip>
                            <IoMdInformationCircleOutline size={16} />
                            <span>Alternar entre temas claro/escuro</span>
                        </ThemeTooltip>
                    )}
                </ThemeToggleWrapper>
            </Navigations>

            <Imagem src={hiImage} alt="Hi" />
        </ContainerPresentation>
    );
}

export default Presentation;
